import React, {useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './login';
import Logout from './logic/logout';
import Private from './pages/private';

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  return token ? children : <Navigate to="/login" />;
};

const App = () => {
  useEffect(() => {
    document.title = 'Collider Client';
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route 
          path="/userboard" 
          element={
            <PrivateRoute>
                <Logout/>
              <Private/>
            </PrivateRoute>
          } 
        />
      </Routes>
    </Router>
  );
};

export default App;
