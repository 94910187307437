import React, { useEffect, useState } from 'react';
import { Web3Provider } from '@ethersproject/providers';
import { formatUnits } from '@ethersproject/units';
import { CDR_ABI, CDR_ADDRESS, API_URL } from '../logic/settings';
import { downloadInit } from '../logic/download';
import { useLocation } from 'react-router-dom';
import { Contract } from '@ethersproject/contracts';
import '../css/private.css';

const amount = "1";   // payment amount
const recipientAddress = "0x49B461D9e1bcF31F43BB4CCdc60507050BeAcC32";   // holder address to send transfer

const Private = () => {
  const [account, setAccount] = useState(null);
  const [fileList, setFileList] = useState('>> File list updating...');
  const [filename, setFileName] = useState('');
  const [balance, setBalance] = useState('0');
  const [logMessages, setLogMessages] = useState('>> Process status:\n'); // State for console logs
  
  // get username
  const location = useLocation();
  const { username } = location.state || {};

  // Override console.log to capture logs
  useEffect(() => {
    const originalConsoleLog = console.log;

    console.log = (...args) => {
      originalConsoleLog(...args);  // Keep default behavior
      setLogMessages((prevLogs) => prevLogs + args.join(' ') + '\n'); // Append logs
    };

    return () => {
      console.log = originalConsoleLog; // Restore console.log on cleanup
    };
  }, []);

  useEffect(() => {

    // --- update balance ---
    const fetchBalance = async () => {
      if (account) {
        //console.log("account: ", account);
        //console.log("addr_token: ", CDR_ADDRESS);
        try {
          const provider = new Web3Provider(window.ethereum);
          const contract = new Contract(CDR_ADDRESS, CDR_ABI, provider);
          const balanceBigNumber = await contract.balanceOf(account);
  
          // Use formatUnits for formatting
          const balance = formatUnits(balanceBigNumber, 18); 
          //console.log("balance: ", balance);
          setBalance(balance);
        } catch (error) {
          console.error(`Error fetching token balance: ${error.message}`);
        }
      }
    };
    fetchBalance();


    // --- update filelist ---
    const fetchFileList = async () => {
      try {
        const response = await fetch(API_URL + 'api/list');
        const data = await response.text();
        setFileList(data);
      } catch (error) {
        setFileList(`ERROR: ${error.message}`);
      }
    };

    fetchFileList();
    const intervalId = setInterval(fetchFileList, 20000);

    return () => clearInterval(intervalId);
  }, [account]);

  // --- connect wallet ---
  const connectMetaMask = async () => {
    if (window.ethereum) {
      try {
        const provider = new Web3Provider(window.ethereum);
        const accounts = await provider.send("eth_requestAccounts", []);
        setAccount(accounts[0]);
      } catch (error) {
        console.error(`Error connecting to MetaMask: ${error.message}`);
      }
    } else {
      console.error('MetaMask not detected');
    }
  };

 // ---------------------------------------------- 
  return (
    <div className="container">
      <h1>Welcome {username}!</h1>
      <text>Connect your wallet to proceed with download</text><br />
      <div>
        <button onClick={connectMetaMask}>
          # Connect MetaMask #
        </button>
        {account && (
          <p>
            Connected account: {account} <br /> Token Balance: {balance} CDR<br /><br />
          </p>
        )}
      </div>

      <h1>File Download</h1>
      <div className="file-list-container">
        <div>
          <input 
            type="text"
            placeholder="Enter filename to download"
            value={filename}
            onChange={(e) => setFileName(e.target.value)}
          />
          <button onClick={() => downloadInit(filename, recipientAddress, amount)}>
            Start Download
          </button>

          <div style={{ marginTop: '20px' }}>
          <h2>Notepad</h2>
          <textarea value={logMessages} readOnly rows="10" style={{ width: '100%' }}></textarea>
        </div>
        </div>

        <div>
          <h2>File List</h2>
          <textarea value={fileList} readOnly></textarea>
        </div>

        
      </div>
    </div>
  );
};

export default Private;
