import { parseUnits } from '@ethersproject/units';
import { CDR_ABI, CDR_ADDRESS, API_URL } from './settings';
import { Web3Provider } from '@ethersproject/providers';
import { Contract } from '@ethersproject/contracts';

export const downloadInit = async (filename, recipientAddress, amount) => {
    // init transfer tx
    const transactionSuccess = await sendTransferTx(recipientAddress, amount);

    if (transactionSuccess) {
        console.log('Transaction successful. Initiating file download...');
        await fileDownload(filename);
    } else {
        console.error('Transaction failed. File download will not proceed.');
    }
};

// download file
const fileDownload = async (filename) => {
    try {
        console.log(`Downloading file: ${filename}`);

        const response = await fetch(API_URL + "req/download", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ fileName: filename }),
        });

        if (response.ok) {
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = filename; // save file
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            console.log(`File ${filename} downloaded successfully.`);
        } else {
            const data = await response.json();
            console.log(`Error: ${data.error}`);
        }
    } catch (error) {
        console.error(`Error requesting file: ${error.message}`);
    }
};

// send tx
const sendTransferTx = async (recipientAddress, amount) => {
    try {
        if (!window.ethereum) {
            console.error('MetaMask is not installed.');
            return false;
        }

        const provider = new Web3Provider(window.ethereum);
        const contract = new Contract(CDR_ADDRESS, CDR_ABI, provider.getSigner());

        const tx = await contract.transfer(recipientAddress, parseUnits(amount, 18)); // 18 decimals
        console.log('Transaction sent:', tx);

        // await tx for success
        const receipt = await tx.wait();
        console.log('Transaction confirmed:', receipt);

        return true;
    } catch (error) {
        console.error('Transaction failed:', error);
        return false;
    }
};
