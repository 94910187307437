export const API_URL = "/";

export const CDR_ADDRESS = "0x6032A509DE6Dd91502333A459d6911E0CD367FB9";
export const CDR_ABI = [{"inputs":[],"stateMutability":"nonpayable","type":"constructor"},
{"inputs":
    [{"internalType":"address","name":"spender","type":"address"},
    {"internalType":"uint256","name":"allowance","type":"uint256"},
    {"internalType":"uint256","name":"needed","type":"uint256"}],
"name":"ERC20InsufficientAllowance","type":"error"},
{"inputs":
    [{"internalType":"address","name":"sender","type":"address"},
    {"internalType":"uint256","name":"balance","type":"uint256"},
    {"internalType":"uint256","name":"needed","type":"uint256"}],
"name":"ERC20InsufficientBalance","type":"error"},
{"inputs":
    [{"internalType":"address","name":"approver","type":"address"}],
"name":"ERC20InvalidApprover","type":"error"},
{"inputs":[{"internalType":"address","name":"receiver","type":"address"}],
"name":"ERC20InvalidReceiver","type":"error"},
{"inputs":[{"internalType":"address","name":"sender","type":"address"}],
"name":"ERC20InvalidSender","type":"error"},
{"inputs":[{"internalType":"address","name":"spender","type":"address"}],
"name":"ERC20InvalidSpender","type":"error"},
{"anonymous":false,
    "inputs":
    [{"indexed":true,"internalType":"address","name":"owner","type":"address"},
    {"indexed":true,"internalType":"address","name":"spender","type":"address"},
    {"indexed":false,"internalType":"uint256","name":"value","type":"uint256"}],
"name":"Approval","type":"event"},
{"anonymous":false,
    "inputs":
    [{"indexed":true,"internalType":"address","name":"from","type":"address"},
    {"indexed":true,"internalType":"address","name":"to","type":"address"},
    {"indexed":false,"internalType":"uint256","name":"value","type":"uint256"}],
"name":"Transfer","type":"event"},
{"inputs":[],
    "name":"_maxTokens",
    "outputs":
    [{"internalType":"uint256","name":"","type":"uint256"}],
    "stateMutability":"view","type":"function"},
    {"inputs":
        [{"internalType":"address","name":"owner","type":"address"},
        {"internalType":"address","name":"spender","type":"address"}],
    "name":"allowance","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],
    "stateMutability":"view","type":"function"},
    {"inputs":
        [{"internalType":"address","name":"spender","type":"address"},
        {"internalType":"uint256","name":"value","type":"uint256"}],
    "name":"approve",
    "outputs":
    [{"internalType":"bool","name":"","type":"bool"}],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":
        [{"internalType":"address","name":"account","type":"address"}],
    "name":"balanceOf",
    "outputs":[{"internalType":"uint256","name":"","type":"uint256"}],
    "stateMutability":"view","type":"function"},
    {"inputs":
        [{"internalType":"uint256","name":"amount","type":"uint256"}],
    "name":"burn","outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[],"name":"decimals","outputs":[{"internalType":"uint8","name":"","type":"uint8"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],"name":"fixMaxTokens","outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"uint256","name":"amount","type":"uint256"}],
    "name":"mint","outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[],"name":"name","outputs":[{"internalType":"string","name":"","type":"string"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],"name":"owner","outputs":[{"internalType":"address","name":"","type":"address"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"uint256","name":"newMax","type":"uint256"}],
    "name":"setMaxTokens","outputs":[],"stateMutability":"nonpayable","type":"function"},
    {"inputs":[],"name":"symbol","outputs":[{"internalType":"string","name":"","type":"string"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[],"name":"totalSupply","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],
    "stateMutability":"view","type":"function"},
    {"inputs":[{"internalType":"address","name":"to","type":"address"},
    {"internalType":"uint256","name":"value","type":"uint256"}],
    "name":"transfer","outputs":[{"internalType":"bool","name":"","type":"bool"}],
    "stateMutability":"nonpayable","type":"function"},
    {"inputs":[{"internalType":"address","name":"from","type":"address"},
    {"internalType":"address","name":"to","type":"address"},
    {"internalType":"uint256","name":"value","type":"uint256"}],
    "name":"transferFrom","outputs":[{"internalType":"bool","name":"","type":"bool"}],
    "stateMutability":"nonpayable","type":"function"}];

